import React from "react";
import ReactDOM from "react-dom/client";
//
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Toaster } from "react-hot-toast";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { globalJson } from "global/global_json";
import { DataProvider } from "data/data-provider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const stripePromise = loadStripe(globalJson.stripePublishableKey);

root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId="792199944204-3u7t512ta8hbrhptdjomhqp2nb7jh41h.apps.googleusercontent.com">
    <Elements stripe={stripePromise}>
      <DataProvider>
        <App />
        <Toaster />
      </DataProvider>
    </Elements>
  </GoogleOAuthProvider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
