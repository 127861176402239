import { FC, Fragment, useEffect, useState } from "react";

import {
  Checkbox,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { StripeCardElementOptions } from "@stripe/stripe-js";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import { useNavigate } from "react-router-dom";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

import toast from "react-hot-toast";

import PrivacyPolicy from "containers/PageHome/PrivacyPolicy";
import { globalJson } from "global/global_json";
import { useData } from "data/data-provider";

import { ProfileChangeFun } from "api/profile/profile";
import { encodeBase64 } from "@progress/kendo-file-saver";
import { getRentalAgreementContent } from "api/rental-agreement/rental-agreement";
import Cancellation from "containers/PageHome/Cancellation";
import { formatDateTime } from "utils/converSelectedDateToString";
import RentalAgreement from "containers/PageHome/RentalAgreement";

export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {
  const signatureStylesText = [
    "Playball",
    "Qwitcher Grypen",
    "Montez",
    "My Soul",
    "Ms Madi",
    "Niconne",
  ];


  const {
    user,
    startDate,
    endDate,
    pickupLocation,
    dropOffLocation,
    setCouponResponse,
    checkCouponResponseLoading,
    setUser,
    selectedCar,
    searchedPickupLoction,
    searchedDropoffLocation,
    bookingdetails,
    callCouponAPI,
    couponResponse,
    checkout,
    callremoveCouponAPI,
  } = useData();
  const [inputValue, setInputValue] = useState("");
  let [isOpen, setIsOpen] = useState(false);
  let [isAgreementOpen, setIsAgreementOpen] = useState(false);
  let [isTermOpen, setIsTermOpen] = useState(false);
  let [isCancellationOpen, setIsCancellationOpen] = useState(false);
  let [rentalAgreementChecked, setRentalAgreementChecked] = useState(false);
  let [loadingDetail, setLoadingDetail] = useState(false);
  let [loading, setLoading] = useState(false);
  let [checkoutLoading, setCheckoutLoading] = useState(false);
  let [privacyPolicyCheckedChecked, setPrivacyPolicyChecked] = useState(false);
  let [agreementContent, setAgreementContent] = useState<any>([]);
  const [privacyPolicy, setPrivacyPolicy] = useState<any>([]);
  const [cancellationPolicy, setCancellationPolicy] = useState<any>([]);
  const [url, setUrl] = useState<any>("");
  let [stripeIntent, setStripeIntent] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState(user?.phone_no ?? "");
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleSave = async () => {
    try {
      setLoadingDetail(true);

      const userId = user?.id;
      if (!userId) {
        console.error("User ID is undefined");
        setLoadingDetail(false);
        return;
      }
      const userDetails = { ...user, phone_no: phoneNumber };

      const formData = new FormData();
      formData.append("phone_no", phoneNumber || "");

      await ProfileChangeFun(formData);
      setUser(userDetails);
      localStorage.setItem("user", JSON.stringify(userDetails));
    } catch (error) {
      console.error("Error updating user profile:", error);
    } finally {
      setLoadingDetail(false);
    }
  };

  useEffect(() => {
    const openRentalAgreement = async () => {
      if (bookingdetails && globalJson && user?.email) {
        const encodedData = encodeBase64(user.email); // Encoding data to UTF-8
        const path = `${bookingdetails.id}b6cr4${globalJson.company_id}b6cr4${encodedData}`;
        
        try {
          const response = await getRentalAgreementContent(path);
          if (response?.data) {
            setAgreementContent(response.data);
            setPrivacyPolicy(response.data.content.privacy_policy);
            setCancellationPolicy(response.data.content.cancellation_policy);
          }
        } catch (error) {
          console.error("Error fetching rental agreement content:", error);
        }
        
        // setUrl(`https://dev.1now.app/pdf?agreement=${path}`);
      }
    };
  
    openRentalAgreement();
  }, [bookingdetails, globalJson, user]); // Add dependencies to avoid unnecessary re-renders
  


  console.log(agreementContent);
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!pickupLocation) {
      navigate("/");
    }
  }, [pickupLocation]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleCouponSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    callCouponAPI(inputValue, bookingdetails?.id);
    setInputValue("");
  };

  const callCheckout = async () => {
    setCheckoutLoading(true);
    // Perform validations (e.g. phone number, agreements)
    if (!user?.phone_no) {
      toast.error("Please provide your phone number");
      setCheckoutLoading(false);
      return;
    }

    // Call the backend to initiate Stripe checkout
    const response = await checkout(bookingdetails?.id);

    // Set Stripe Intent and open the payment modal
    setStripeIntent(response?.data);
    setIsOpen(true);
    setCheckoutLoading(false);
  };

  // Handle payment submission
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setLoading(true);

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      toast.error("Card element not found.");
      setLoading(false);
      return;
    }

    // Confirm payment with the client secret from the backend
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      stripeIntent?.payment_intent_client_secret ?? "",
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: user?.first_name,
            email: user?.email,
          },
        },
      }
    );

    if (error) {
      toast.error(error.message || "Payment failed. Please try again.");
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      toast.success("Payment successful!");
      navigate(`/pay-done/${bookingdetails?.id}`);
    }

    setLoading(false);
  };

  function close() {
    setIsOpen(false);
  }
  console.log(stripeIntent?.payment_intent_client_secret);
  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full">
            <div className=" w-full aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover w-full"
                sizes="200px"
                src={
                  (selectedCar?.fleet_photos?.length ?? 0) > 0
                    ? `https://fleet-management-images-upload-be.s3.amazonaws.com/${selectedCar?.fleet_photos[0].photo_url}`
                    : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
                }
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="w-full max-w-sm mx-auto">
            <h2 className="text-xl font-medium text-gray-900 dark:text-gray-100">
              Rental Bill
            </h2>
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
              Prices may change depending on the length of the rental and the
              price of your rental car.
            </p>
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Subtotal
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {" "}
                    ($
                    {(selectedCar?.price ?? 0).toFixed(2)} *{" "}
                    {bookingdetails?.trip_days} days)
                  </span>
                </div>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${bookingdetails?.invoice?.discounted_car_total}
                </span>
              </div>
            </div>

            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Location Total
                </span>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${bookingdetails?.invoice?.location_total}
                </span>
              </div>
            </div>

            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Extras Total
                </span>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${bookingdetails?.invoice?.extras_total}
                </span>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Insurance Total
                </span>
                <span className="lg:text-2xl sm:text-xl font-medium text-gray-900 dark:text-gray-100">
                  ${bookingdetails?.invoice?.insurance_total}
                </span>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Tax
                </span>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${bookingdetails?.invoice?.tax}
                </span>
              </div>
            </div>

            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Discount
                </span>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${bookingdetails?.invoice?.discount}
                </span>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 dark:border-gray-700 pt-4">
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Total Rental Price
                  </span>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                    Overall price rental
                  </p>
                </div>
                <div>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    $
                    {bookingdetails?.invoice
                      ?.total_price_after_coupon_without_security
                      ? bookingdetails?.invoice
                          ?.total_price_after_coupon_without_security
                      : bookingdetails?.invoice?.total_price_without_security}
                  </span>
                </div>
              </div>
            </div>

            {bookingdetails?.invoice?.security_deposit_total !== 0 && (
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Deposit Fee
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice?.security_deposit_total}
                  </span>
                </div>
              </div>
            )}

            {couponResponse !== null && (
              <div className="mt-4 ">
                <div className="flex justify-between items-center">
                  <div className="flex flex-wrap  border border-grey rounded-full py-1">
                    <span className="text-lg font-normal text-gray-700 dark:text-gray-300 pl-4">
                      {couponResponse?.coupon_info?.name}
                    </span>
                    <span className="text-lg text-green-600 font-medium pl-1">
                      (
                      {couponResponse?.coupon_info?.coupon_type === "amount"
                        ? `$${couponResponse?.coupon_info?.amount} off`
                        : `${couponResponse?.coupon_info?.coupon_percentage}% off`}
                      )
                    </span>
                    <ButtonCircle
                      onClick={() => {
                        setCouponResponse(null);
                        callremoveCouponAPI(
                          couponResponse?.coupon_info?.name,
                          bookingdetails?.id
                        );
                      }}
                      size="w-6 h-6"
                      className=" self-center mr-2 ml-4"
                    >
                      <XMarkIcon className="w-4 h-4" />
                    </ButtonCircle>
                  </div>
                  <span className="text-2xl text-green-600 font-semibold">
                    -${couponResponse?.coupon_info?.amount}
                  </span>
                </div>
              </div>
            )}
            {couponResponse === null && (
              <form
                onSubmit={handleCouponSubmit}
                className="mt-10 relative max-w-sm"
              >
                <Input
                  value={inputValue}
                  onChange={handleInputChange}
                  required
                  aria-required
                  placeholder="Promo Code"
                  type="text"
                  rounded="rounded-full"
                />
                <ButtonCircle
                  type="submit"
                  className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
                >
                  {checkCouponResponseLoading ? (
                    <div className="flex justify-center items-center col-span-full">
                      <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                    </div>
                  ) : (
                    <i className="las la-arrow-right text-xl"></i>
                  )}
                </ButtonCircle>
              </form>
            )}

            <ButtonPrimary className="w-full mt-4" onClick={callCheckout}>
              {checkoutLoading ? (
                <div className="flex justify-center items-center col-span-full">
                  <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                </div>
              ) : (
                "Pay and Book"
              )}
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">Trip Details</h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <h3 className="text-2xl font-semibold">Location</h3>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <div className="flex flex-col flex-1 p-5">
              <span className="text-sm text-neutral-400">Pick up Location</span>
              <span className="mt-1.5 text-lg font-semibold">
                {pickupLocation?.address}
              </span>
            </div>

            <div className="flex flex-col flex-1 p-5">
              <span className="text-sm text-neutral-400">
                Drop off Location
              </span>
              <span className="mt-1.5 text-lg font-semibold">
                <span className="line-clamp-1">
                  {dropOffLocation
                    ? dropOffLocation?.address
                    : pickupLocation?.address}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-2xl font-semibold">Phone Number</h3>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <div className="flex flex-col flex-1 p-5">
              <label htmlFor="phone" className="text-sm text-neutral-400">
                Contact Number
              </label>
              <div className="flex mt-1.5">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="block w-full px-3 py-2 border-none rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-transparent text-lg font-semibold"
                  placeholder="Enter your phone number"
                />
                <button
                  type="button"
                  onClick={handleSave}
                  className="ml-2 px-4 py-2 bg-primary-500 text-white rounded-md text-lg font-semibold min-w-[80px]"
                >
                  {loadingDetail ? (
                    <div className="flex justify-center items-center col-span-full">
                      <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                    </div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Date & Time</h3>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <div className="flex flex-col flex-1 p-5">
              <span className="text-sm text-neutral-400">
                Pick up Date & Time
              </span>
              <span className="mt-1.5 text-lg font-semibold">
                {formatDateTime(startDate)}
              </span>
            </div>

            <div className="flex flex-col flex-1 p-5">
              <span className="text-sm text-neutral-400">
                Drop off Date & Time
              </span>
              <span className="mt-1.5 text-lg font-semibold">
                <span className="line-clamp-1"> {formatDateTime(endDate)}</span>
              </span>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Confirmation</h3>

          <div className="flex gap-2 mt-6 p-4 border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden z-10">
            <Checkbox
              checked={rentalAgreementChecked}
              onChange={(checked) => {
                setIsAgreementOpen(true);
              }}
              className="group size-7 rounded-md  p-1 ring-1 ring-[#3CDCDC] ring-inset  mr-4"
            >
              <CheckIcon
                className={`size-5 stroke-[#3CDCDC] ${
                  rentalAgreementChecked ? "visible" : "invisible"
                }`}
              />
            </Checkbox>
            <div>
              <div className="text-gray-400 text-lg font-medium ">
                I've reviewed and accepted the Rental Agreement
              </div>
              <div
                className="text-gray-800 dark:text-gray-200 text-xl font-bold cursor-pointer underline"
                onClick={() => {
                  setIsAgreementOpen(true);
                }}
              >
                Rental Agreement{" "}
              </div>
            </div>
          </div>
          {/* <div className="overflow-x-auto lg:hidden flex mt-6">
            {signatureStylesText.map((font, index) => (
              <div
                key={index}
                className="mr-4 w-64"
                onClick={() => {
                  setSelectedSignatureIndex(index);
                }}
              >
                <div
                  className={` border rounded-lg mb-3 p-4 flex items-center ${
                    selectedSignatureIndex === index
                      ? "border-green-600 shadow-lg"
                      : "border-gray-200"
                  }`}
                >
                  <p
                    style={{ fontFamily: font }}
                    className="text-l text-secondary w-64"
                  >
                    {`${stripeVerification?.person_details?.first_name ?? ""} ${
                      stripeVerification?.person_details?.last_name ?? ""
                    }`}
                  </p>
                </div>
              </div>
            ))}
          </div> */}
          <div className="flex gap-2 mt-6 p-4 border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden z-10">
            <Checkbox
              checked={privacyPolicyCheckedChecked}
              onChange={(checked) => {
                setPrivacyPolicyChecked(checked);
              }}
              className="group size-7 rounded-md  p-1 ring-1 ring-[#3CDCDC] ring-inset  mr-4"
            >
              <CheckIcon
                className={`size-5 stroke-[#3CDCDC] ${
                  privacyPolicyCheckedChecked ? "visible" : "invisible"
                }`}
              />
            </Checkbox>
            <div>
              <div className="text-gray-400 text-lg font-medium ">
                I agree to the privacy policy and cancellation policy
              </div>

              <div
                className="text-gray-800 dark:text-white text-xl font-bold cursor-pointer underline"
                onClick={() => {
                  setIsTermOpen(true);
                }}
              >
                Privacy Policy
              </div>
              <span className="text-gray-800 dark:text-white text-xl font-bold cursor-pointer">
                &
              </span>
              <div
                className="text-gray-800 dark:text-white text-xl font-bold cursor-pointer underline"
                onClick={() => {
                  setIsCancellationOpen(true);
                }}
              >
                Cancellation Policy
              </div>
            </div>
          </div>
        </div>
        <div className="lg:hidden flex-grow">{renderSidebar()}</div>
        {/* <div className="px-4 py-2">
          <div className="hidden lg:flex lg:overflow-x-scroll">
            {signatureStylesText.map((font, index) => (
              <div
                key={index}
                className="mr-4 w-64"
                onClick={() => {
                  setSelectedSignatureIndex(index);
                }}
              >
                <div
                  className={` border rounded-lg mb-3 p-4 flex items-center ${
                    selectedSignatureIndex === index
                      ? "border-green-600 shadow-lg"
                      : "border-gray-200"
                  }`}
                >
                  <p
                    style={{ fontFamily: font }}
                    className="text-l text-secondary w-64"
                  >
                    {`${stripeVerification?.person_details?.first_name ?? ""} ${
                      stripeVerification?.person_details?.last_name ?? ""
                    }`}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    );
  };

  const cardStyle: StripeCardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  function padToTwoDigits(number: number) {
    return number.toString().padStart(2, "0");
  }

  return (
    <>
      <div className={`nc-CheckOutPagePageMain ${className}`}>
        <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
          <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
            {renderMain()}
          </div>
          <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
        </main>
      </div>
      <Transition appear show={isOpen}>
        <Dialog
          as="div"
          className="relative z-10 focus:outline-none"
          onClose={() => setIsOpen(false)}
        >
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 transform-[scale(95%)]"
                enterTo="opacity-100 transform-[scale(100%)]"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 transform-[scale(100%)]"
                leaveTo="opacity-0 transform-[scale(95%)]"
              >
                <DialogPanel className="w-full max-w-md bg-primary-6000 rounded-x p-6 backdrop-blur-2xl rounded-xl">
                  <div className="flex justify-end">
                    <button
                      onClick={() => setIsOpen(false)}
                      className="text-white"
                    >
                      <XMarkIcon className="h-6 w-6" />
                    </button>
                  </div>
                  <DialogTitle
                    as="h3"
                    className="font-medium m-5 text-white text-2xl"
                  >
                    Payment
                  </DialogTitle>
                  <form onSubmit={handleSubmit} className="m-5">
                    <label
                      htmlFor="card-element"
                      className="bloc font-sm mb-2 text-white text-xl"
                    >
                      Card Details
                    </label>
                    <div className="border rounded-lg p-3">
                      <CardElement
                        id="card-element"
                        options={cardStyle}
                        className="p-3"
                      />
                    </div>{" "}
                    <ButtonPrimary
                      type="submit"
                      disabled={!stripe || loading}
                      className="w-full mt-4 bg-white hover:bg-white text-primary-6000"
                    >
                      {loading ? (
                        <div className="flex justify-center items-center">
                          <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-primary-200"></div>
                        </div>
                      ) : (
                        "Pay"
                      )}
                    </ButtonPrimary>
                  </form>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isTermOpen} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={() => {
            setIsTermOpen(false);
          }}
          static={true}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <DialogPanel className="relative h-full w-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={close}
                      >
                        <XMarkIcon
                          onClick={() => {
                            setIsTermOpen(false);
                          }}
                          className="w-5 h-5 text-black dark:text-white"
                        />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <div className="flex-1 relative flex z-10 ">
                            <div className="grid justify-center w-full overflow-hidden rounded-none p-6">
                              <PrivacyPolicy terms={privacyPolicy} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" justify-end px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex">
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          setPrivacyPolicyChecked(true);
                          setIsTermOpen(false);
                        }}
                      >
                        Accept
                      </ButtonPrimary>
                    </div>
                  </>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isAgreementOpen} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={() => {
            setIsAgreementOpen(false);
          }}
          static={true}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <DialogPanel className="relative h-full w-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={close}
                      >
                        <XMarkIcon
                          onClick={() => {
                            setIsAgreementOpen(false);
                          }}
                          className="w-5 h-5 text-black dark:text-white"
                        />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800 z-10">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <div className="grid justify-center w-full overflow-hidden rounded-none p-6">
                            <RentalAgreement data={agreementContent} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" justify-end px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex">
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          setRentalAgreementChecked(true);
                          setIsAgreementOpen(false);
                        }}
                      >
                        Accept
                      </ButtonPrimary>
                    </div>
                  </>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isCancellationOpen} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={() => {
            setIsCancellationOpen(false);
          }}
          static={true}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <DialogPanel className="relative h-full w-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4 z-0">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={close}
                      >
                        <XMarkIcon
                          onClick={() => {
                            setIsCancellationOpen(false);
                          }}
                          className="w-5 h-5 text-black dark:text-white"
                        />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <div className="flex-1 relative flex z-10 ">
                            <div className="grid justify-center w-full overflow-hidden rounded-none p-6">
                              <Cancellation terms={cancellationPolicy} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" justify-end px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex">
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          setPrivacyPolicyChecked(true);
                          setIsCancellationOpen(false);
                        }}
                      >
                        Accept
                      </ButtonPrimary>
                    </div>
                  </>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CheckOutPagePageMain;
