import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import { getProfileFun, ProfileChangeFun } from "api/profile/profile";
import { profileChangePayload } from "api/types";
import toast from "react-hot-toast";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const [profile, setProfile] = useState<profileChangePayload | null>(null);
  const [originalProfile, setOriginalProfile] = useState<profileChangePayload | null>(null); // Store the original profile
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    const fetchProfile = async () => {
      const response = await getProfileFun();
      if (response.data) {
        setProfile(response.data);
        setOriginalProfile(response.data); // Store the original profile
      }
    };
    fetchProfile();
  }, []);

  const hasProfileChanged = (): boolean => {
    return JSON.stringify(profile) !== JSON.stringify(originalProfile); // Check if profile has changed
  };

  const handleProfileUpdate = async () => {
    if (!profile) return;

    setLoading(true);

    const formData = new FormData();
    formData.append("first_name", profile.first_name || "");
    formData.append("last_name", profile.last_name || "");
    formData.append("phone_no", profile.phone_no || "");

    if (file) {
      formData.append("profile_picture", file); // Append the file to FormData
    }

    const response = await ProfileChangeFun(formData);
    if (response.data) {
      setProfile(response?.data);
      setOriginalProfile(response?.data); // Update original profile to match the new data
      toast.success("Profile updated successfully");
    } else {
      toast.error("There was an error updating profile");
    }

    setLoading(false);
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <h2 className="text-3xl font-semibold">Account information</h2>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar
                  sizeClass="w-32 h-32"
                  imgUrl={profile?.profile_picture || ""}
                />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg width="30" height="30" fill="none">
                    {/* SVG for icon */}
                  </svg>
                  <span className="mt-1 text-xs">Change Image</span>
                </div>
                <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={(e) => {
                    const selectedFile = e.target.files?.[0];
                    if (selectedFile) {
                      setFile(selectedFile); // Store the file in state
                      setProfile({
                        ...profile,
                        profile_picture: URL.createObjectURL(selectedFile),
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div>
                <label>First Name</label>
                <Input
                  className="mt-1.5"
                  value={profile?.first_name || ""}
                  onChange={(e) =>
                    setProfile({ ...profile, first_name: e.target.value })
                  }
                />
              </div>
              <div>
                <label>Last Name</label>
                <Input
                  className="mt-1.5"
                  value={profile?.last_name || ""}
                  onChange={(e) =>
                    setProfile({ ...profile, last_name: e.target.value })
                  }
                />
              </div>
              <div>
                <label>Phone number</label>
                <Input
                  className="mt-1.5"
                  value={profile?.phone_no || ""}
                  onChange={(e) =>
                    setProfile({ ...profile, phone_no: e.target.value })
                  }
                />
              </div>
              <div>
                <label>Email</label>
                <Input
                  className="mt-1.5 cursor-not-allowed"
                  value={profile?.email || ""}
                  disabled={true} // Email is not editable
                />
              </div>
              <div className="pt-2">
                <ButtonPrimary
                  onClick={handleProfileUpdate}
                  className="min-w-[150px]"
                  disabled={!hasProfileChanged() || loading} // Disable button if profile hasn't changed or if loading
                >
                  {loading ? (
                    <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                  ) : (
                    "Update info"
                  )}
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
