import React from "react";

function GlendaleContent() {
  return (
    <div>
      {" "}
      <div className="p-8 max-w-3xl mx-auto">
        <span className="mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center">
          Explore Glendale and the Valley with Auto Rentals AZ
        </span>
        <span className="text-left font-sans font-medium text-base leading-7">
          No matter your reason for visiting or residing in Glendale or the
          Valley, Auto Rentals AZ is here to help you explore this charming city
          and its surrounding areas at your own pace. With our wide range of
          vehicles and commitment to exceptional service, we’re your trusted
          partner for all your transportation needs in and around the valley.
          <br />
          <br />
          Ready to rent a car in Glendale? Contact Auto Rentals AZ today, or
          conveniently book online to secure your vehicle for your upcoming
          journey. We look forward to being a part of your Valley adventure!
          Additionally, we go over the top to ensure a memorable experience. At
          Auto Rentals AZ, we are changing the way you rent a car. No lines,
          just show up, get the exact vehicle you purchased so you can go
          Places! <br />
          <br />
          Need the car delivered to your Hotel? Let us know and we can
          accommodate any delivery.
        </span>
        <span className="mt-12 mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center">
          Things to do & eat in Glendale, Arizona
        </span>
        <ol className="list-decimal list-inside space-y-4 text-base font-medium leading-7 text-[#1B2430] text-justify">
          <li>Westgate Entertainment</li>
          <li>Desert Diamond Arena</li>
          <li>Little Saigon</li>
          <li>Arrowhead Grill</li>
          <li>Yard House</li>
          <li>Top Golf</li>
          <li>Pop Stroke</li>
        </ol>
      </div>
    </div>
  );
}

export default GlendaleContent;
