import clientSay1 from "images/clientSay1.jpg";
import clientSay4 from "images/clientSay4.jpg";
import clientSay5 from "images/clientSay5.jpg";

export const globalJson = {
  company_id: 1,

  // apiUtil auth header key
  authToken: "Bearer 168|3oLFn8OniSxk1UBTO3eQeGXMuViDTsZqAwhalwWm",
  companyId: "7514a219-48ce-439e-a2c5-6f38980cee1a",
  stripePublishableKey:
    "pk_live_51LdnoABqYa4ICTHoC6CjEgImHYOvUXXPs8psg8jZqXPxRwBt99nQEWtFFJj2n3ublkCJa0S8pYzDtvStF2JxUPzU00uWCskvlb",
  mapKey: "AIzaSyDKEntg7JydrxI7zr2tQL5OYcS5M-er-bc",
  residenceStateCode: "AZ",

  //email confirmation admin token
  adminEmailToken:
    "XR85jFku2zMMqvsYmAdkrk1lm6pozj7eFXetRHLNSSsLxRLnAHgY3dPaVsE27OK8cIbnhFQmncWxVjS1XLAhneFEFpwVVeaNE7hMOym2vrhzdKSmxn99V0fLvVJyZLPT",
  websiteLink: "https://autorentalsaz-dev.1now.app",
  sender: "autorentalsaz",
  ccEmail: "Autorentalsaz@gmail.com",

  // apiUtil sendNewsLetterEmail
  util_serviceId: "service_2xca7kj",
  util_templateId: "template_smckeo7",
  util_userId: "ATdsFr5wumje11YkV",
  util_privateKey: "TJyyV_e7nNOGPKbyyjpMB",
  util_contact_templateId: "template_9dsuz0w",

  // apiUtil getBonzahToken
  client_secret_dev: "",
  client_id_dev: "",
  client_secret_prod: "",
  client_id_prod: "",

  about_us_para:
    "At Level305, we believe that the journey is just as important as the destination. Whether you’re a local resident or a visitor to the beautiful Port St. Lucie area, we are here to provide you with reliable, affordable, and high-quality car rental solutions tailored to meet your needs.",

  clientSays_arr: [
    {
      id: 1,
      clientName: "Jorge V",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Pick up was easy and smooth. The car is well maintained very clean drives nice definitely would get it again.",
      img: clientSay1,
    },
    {
      id: 2,
      clientName: "Brooke F.",
      clientAddress: "LondDodge Grand Caravan 2019",
      content:
        "The car was as shown in photos. It was great for our party of seven on our trip. Sam was accommodating and helpful the entire trip. We will use this car again the next time we do a family trip like this",
      img: clientSay4,
    },
    {
      id: 3,
      clientName: "Justice B.",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "I loved the pickup/drop off location. Was right off the highway making it easy to get back on and drive to your destination. Sam was very communicative and accommodating. allowed me to pick up earlier than expected as well change my drop off time. Also, loved the car!",
      img: clientSay5,
    },
  ],

  //social links
  facebook:
    "https://www.facebook.com/profile.php?id=61560083231842&mibextid=LQQJ4d",
  instagram:
    "https://www.instagram.com/autorentalsazz?igsh=Mnc2cXZmbTl1aXFs&utm_source=qr",

  //contact
  address: "Phoenix AZ 85085",
  addressLink:
    "https://www.google.com/maps/place/4830+W+Kennedy+Blvd,+Tampa,+FL+33609,+USA/data=!4m2!3m1!1s0x88c2c2e4b61fbe87:0x7fa31f86943d0895?sa=X&ved=1t:242&ictx=111",
  phone: "+1 (248) 219-4849", //pending
  email: "Autorentalsaz@gmail.com", //pending
};

export const scriptHubSpot = document.createElement("script");
scriptHubSpot.src = "//js-na1.hs-scripts.com/46776911.js";
scriptHubSpot.async = true;
scriptHubSpot.defer = true;
scriptHubSpot.id = "hs-script-loader";
