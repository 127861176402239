import rightImg from "images/image.png";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionHero from "containers/PageAbout/SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import SectionGridCategoryBox from "./SectionGridLocation";
import HomeAboutSection from "containers/PageAbout/HomeAboutSection";
import WhyLevel305 from "containers/why305/why305";
import ReadytoDrive from "containers/PageAbout/ReadytoDrive";

function PageHome3() {
  return (
    <div className="nc-PageHome3 relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      {/* <DownloadLink /> */}

      {/* SECTION HERO */}
      {/* <div className="container relative lg:pb-16 lg:pt-8 md:pt-0 sm:pt-0 px-0 md:pb-0 sm:pb-0 "> */}
      <SectionHero3 className="" />

      {/* </div> */}
      <div className="container relative py-16">
        <SectionGridFeaturePlaces />
        {/* <SectionGridCategoryBox /> */}
      </div>

      <div className="py-36 px-12 relative bg-[#00000066]">
        <BgGlassmorphism />
        <HomeAboutSection rightImg={rightImg} />
      </div>

      <div className="py-16">
        <div>
          <WhyLevel305 />
        </div>
        <div className="relative py-16 lg:py-28 mb-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>
        <div className=" p-16 mb-16">
          <ReadytoDrive />
        </div>
      </div>
    </div>
  );
}

export default PageHome3;
