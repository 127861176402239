import { CanopyPayload, CanopyResponse, StripePayload, StripeResponse } from "api/types";
import { AxiosError } from "axios";
import customAxios from "config";

export const CreateCanopyVerification = async (
    payload: CanopyPayload
  ): Promise<{ data?: CanopyResponse; error?: string }> => {
    try {
      const response = await customAxios.post<CanopyResponse>(
        "/api/canopy/create-insurance-verification-link/",
        payload
      );
      return response
    } catch (error) {
      if (error instanceof AxiosError) {
        return {
          error: error.response?.data?.detail || "An error occurred during canopy verification.",
        };
      }
      return { error: "An unknown error occurred." };
    }
  };