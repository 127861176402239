import { StripePayload, StripeResponse } from "api/types";
import { AxiosError } from "axios";
import customAxios from "config";

export const CreateStripeVerification = async (
  payload: StripePayload
): Promise<{ data?: StripeResponse; error?: string }> => {
  try {
    const response = await customAxios.post<StripeResponse>(
      "/api/identity/create_verification_session/",
      payload
    );
    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred during stripe verification.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const CreateStripeIntent = async (
  payload: any
): Promise<{ data?: any; error?: string }> => {
  console.log(payload);
  try {
    const response = await customAxios.post<any>(
      "/api/payment/create_payment_intent/",
      payload
    );
    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred during creating stripe intent",
      };
    }
    return { error: "An unknown error occurred." };
  }
};
