import React from "react";

function PhoenixContent() {
  return (
    <div>
      {" "}
      <div className="p-8 max-w-3xl mx-auto">
        <span className="mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center">
          Explore Phoenix and the Valley with Auto Rentals AZ
        </span>
        <span className="text-left font-sans font-medium text-base leading-7">
          No matter your reason for visiting or residing in Phoenix or the
          Valley, Auto Rentals AZ is here to help you explore this charming city
          and its surrounding areas at your own pace. With our wide range of
          vehicles and commitment to exceptional service, we’re your trusted
          partner for all your transportation needs in and around the valley.
          <br />
          <br />
          Ready to rent a car in Phoenix? Contact Auto Rentals AZ today, or
          conveniently book online to secure your vehicle for your upcoming
          journey. We look forward to being a part of your Valley adventure!
          Additionally, we go over the top to ensure a memorable experience. At
          Auto Rentals AZ, we are changing the way you rent a car. No lines,
          just show up, get the exact vehicle you purchased so you can go
          Places! <br />
          <br />
          Need the car delivered to your Hotel? Let us know and we can
          accommodate any delivery.
        </span>
        <span className="mt-12 mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center">
          Best food in Phoenix, Arizona
        </span>
        <ol className="list-decimal list-inside space-y-4 text-base font-medium leading-7 text-[#1B2430] text-justify">
          <li>
            Steak 44
            <br /> Address: 5101 N 44th St, Phoenix, AZ
          </li>
          <li>
            Tia Carmen <br />
            Address: 5350 E Marriott Dr, Phoenix, AZ
          </li>
          <li>
            Sicilian Butcher <br />
            Address: 15530 N Tatum Blvd, Phoenix, AZ
          </li>
          <li>
            Different Pointe of View <br />
            Address: 11111 N 7th St, Phoenix, AZ
          </li>
          <li>
            The Henry
            <br />
            Address: 4455 E Camelback Rd, Phoenix, AZ
          </li>
          <li>
            Gertrude’s
            <br />
            Address: 1201 N Galvin Pkwy, Phoenix, AZ
          </li>
          <li>
            North Italia
            <br />
            Address: 4925 n 40th St, Phoenix, AZ
          </li>
        </ol>
      </div>
    </div>
  );
}

export default PhoenixContent;
