import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import rightImg from "images/readytodrive.png";

export interface SectionHeroProps {
  className?: string;
}

const ReadytoDrive: FC<SectionHeroProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero relative dark:bg-black dark:bg-opacity-20 nc-BackgroundSection p-16 inset-y-0 w-screen xl:max-w-[1340px] 2xl:max-w-screen-2xl left-1/2 transform -translate-x-1/2 xl:rounded-[40px] z-0  ${className}  `}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-[#E5E7EB]">
            Book your ride today!
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 text-justify">
            Ready to hit the road? Booking with Driven Enterprises is fast and
            simple. Browse our fleet online, choose your vehicle, and reserve it
            within minutes. For personalized assistance, feel free to contact
            our team directly.
          </span>
          <ButtonPrimary className="nc-Button relative h-auto inline-flex items-center justify-center rounded-xl transition-colors px-6 py-3 text-sm sm:text-base font-medium">
            Book a Car
          </ButtonPrimary>
        </div>
        <div className="flex flex-row justify-center lg:justify-end ">
          <img className="w-[80%]  rounded-xl" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ReadytoDrive;
