import React from "react";

function ScottsdaleContent() {
  return (
    <div>
      {" "}
      <div className="p-8 max-w-3xl mx-auto">
        <span className="mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center">
          Explore Scottsdale and the Valley with Auto Rentals AZ
        </span>
        <span className="text-left font-sans font-medium text-base leading-7">
          No matter your reason for visiting or residing in Scottsdale or the
          Valley, Auto Rentals AZ is here to help you explore this charming city
          and its surrounding areas at your own pace. With our wide range of
          vehicles and commitment to exceptional service, we’re your trusted
          partner for all your transportation needs in and around the valley.
          <br />
          <br />
          Ready to rent a car in Scottsdale? Contact Auto Rentals AZ today, or
          conveniently book online to secure your vehicle for your upcoming
          journey. We look forward to being a part of your Valley adventure!
          Additionally, we go over the top to ensure a memorable experience. At
          Auto Rentals AZ, we are changing the way you rent a car. No lines,
          just show up, get the exact vehicle you purchased so you can go
          Places! <br />
          <br />
          Need the car delivered to your Hotel? Let us know and we can
          accommodate any delivery
        </span>
        <span className="mt-12 mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center">
          Best food in Scottsdale, Arizona
        </span>
        <ol className="list-decimal list-inside space-y-4 text-base font-medium leading-7 text-[#1B2430] text-justify">
          <li>
            Ocean 44
            <br /> Address: 4748 N Goldwater Blvd, Scottsdale, AZ 85251
          </li>
          <li>
            Nobu <br />
            Address: 7014 E Camelback rd, Scottsdale, AZ 85251
          </li>
          <li>
            Toca Madera <br />
            Address: 4736 N Goldwater blvd, Scottsdale, AZ 85251
          </li>

          <li>
            Malee’s Thai
            <br />
            Address: 7131 E Main St, Scottsdale, AZ 85251
          </li>
          <li>
            Pizza Bianco
            <br />
            Address: 4743 n 20th st, Phoenix, AZ 85016 
          </li>
          <li>
            Sumomaya
            <br />
            Address: 6560 N Scottsdale rd, Scottsdale, AZ 85253
          </li>
          <li>
            Olive & Ivy
            <br />
             Address: 7135 E Camelback rd, Scottsdale, AZ 85251
          </li>
        </ol>
        <span className="my-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center">
          Best Golf Courses in Arizona
        </span>
        <ol className="list-decimal list-inside space-y-4 text-base font-medium leading-7 text-[#1B2430] text-justify">
          <li>Quintero Golf Course</li>
          <li>Wickenburg Ranch</li>
          <li>We-ko-pa Saguaro</li>
          <li>Grayhawk</li>
          <li>Troon Monument</li>
          <li>TPC Scottsdale</li>
          <li>Verrado Victory</li>
          <li>Eagle Mountain</li>
          <li>We Ko Pa Cholla</li>
        </ol>
        <span className="my-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center">
          Best Hotels in AZ
        </span>
        <ol className="list-decimal list-inside space-y-4 text-base font-medium leading-7 text-[#1B2430] text-justify">
          <li>The Phoenician</li>
          <li>Four Seasons Troon</li>
          <li>JW Desert Ridge</li>
          <li>Andaz Scottsdale</li>
          <li>W Scottsdale</li>
          <li>Fairmont Princess</li>
          <li>Hyatt Gainey Ranch</li>
          <li>Hotel Valley Ho</li>
          <li>Global Ambassador</li>
          <li>Mountain Shadows</li>
        </ol>
      </div>
    </div>
  );
}

export default ScottsdaleContent;
