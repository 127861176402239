
import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useData } from "data/data-provider";

function CarDetailImageCollage() {
  const { selectedCar } = useData();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const modal = searchParams.get("modal");
  const thisPathname = location.pathname;
  console.log(selectedCar);

  const getImageGalleryListing = (): ListingGalleryImage[] => {
    // Check if selectedCar and listing_images are defined
    if (selectedCar && selectedCar.fleet_photos) {
      return selectedCar.fleet_photos.map(
        (item, index): ListingGalleryImage => ({
          id: index,
          url: item.photo_url,
        })
      );
    } else {
      // Return an empty array if selectedCar or listing_images is undefined
      return [];
    }
  };

  const handleCloseModalImageGallery = () => {
    const params = new URLSearchParams(searchParams);
    params.delete("modal");
    navigate(`${thisPathname}?${params.toString()}`); // Removed extra slash
  };

  const handleOpenModalImageGallery = () => {
    navigate(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const imageGallery = getImageGalleryListing();

  return (
    <div className={` nc-ListingCarDetailPage `}>
      {/* SINGLE HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-4 gap-1 sm:gap-2 lg:min-h-96 md:min-h-0">
          <div
            className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={handleOpenModalImageGallery}
          >
            <img
              src={
                (selectedCar?.fleet_photos?.length ?? 0) > 0
                  ? `https://fleet-management-images-upload-be.s3.amazonaws.com/${selectedCar?.fleet_photos[0].photo_url}`
                  : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
              }
              alt="0"
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>

          {/*  */}
          <div
            className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={handleOpenModalImageGallery}
          >
            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={
                (selectedCar?.fleet_photos?.length ?? 0) > 1
                  ? `https://fleet-management-images-upload-be.s3.amazonaws.com/${selectedCar?.fleet_photos[1].photo_url}`
                  : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
              }
              alt="1"
              sizes="400px"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>

          {/*  */}
          {selectedCar?.fleet_photos
            .filter((_, i) => i >= 2 && i < 4)
            .map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 2 ? "block" : ""
                }`}
              >
                <div className="aspect-w-4 aspect-h-3">
                  <img
                    className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl "
                    src={`https://fleet-management-images-upload-be.s3.amazonaws.com/${item.photo_url}` || ""}
                    alt="photos"
                    sizes="400px"
                  />
                </div>

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={handleOpenModalImageGallery}
                />
              </div>
            ))}

          <div
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="h-5 w-5" />

            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Show all photos
            </span>
          </div>
        </div>
      </header>

      {/* MAIn */}
    </div>
  );
}

export default CarDetailImageCollage;
