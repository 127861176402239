import React from "react";

function RentalAgreement({ data }: { data: any }) {
  function formatDate(dateString: string) {
    const date = new Date(dateString);

    // Correct options for formatting the date
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    // Get the formatted date
    const formattedDate = date.toLocaleString("en-GB", options);

    return formattedDate;
  }
  function toSentenceCase(str: string) {
    if (!str || typeof str !== "string") return str; // Return as is if not a string

    // Convert the first letter to uppercase and the rest to lowercase
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  return (
    <div className="mt-6 rounded-lg border bg-white p-6 pb-8 pt-5">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-[#00283E]">
            Car Rental Agreement
          </h1>
          <p className="max-96 text-sm text-[#86909C]">
            Please note the following booking Terms and Conditions apply to
            bookings placed directly on the “Company Name” website.
          </p>
        </div>
      </div>

      <div className="mb-4 mt-8 flex flex-col md:flex-row">
        {/* <div className="me-0 flex flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-3 md:me-5 md:w-[150px]">
          <div className="w-[50px]">
            <UploadIcon />
          </div>
          <p>Upload logo</p>
          <p>Recommended size</p>
          <p>100x100</p>
        </div> */}
        <div className="mt-4 flex flex-col justify-center md:mt-0">
          <h2 className="text-xl font-semibold text-[#00283E]">
            {data?.company?.name}
          </h2>
          <p className="pt-2 text-[#00283E]">{data?.company?.address}</p>
          <p className="pt-2 text-[#00283E]">{data?.company?.phone_no}</p>
          <p className="pt-2 text-[#00283E]">{data?.company?.email}</p>
        </div>
      </div>

      <div>
        <div className="flex flex-col md:flex-row">
          <div className="me-3 w-full">
            <div className="rounded-lg border border-muted">
              <div className="header rounded-lg bg-[#f7f7f7] px-5 py-3">
                <h4 className="text-lg">Customer Information</h4>
              </div>

              <div className="header border-t border-muted p-5">
                <div className="grid grid-cols-1 gap-3 rounded-lg md:grid-cols-1">
                  <span className="text-base font-bold text-[#777777]">
                    Customer Name
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                    {data?.booking_data?.customer?.first_name}{" "}
                    {data?.booking_data?.customer?.last_name}
                  </h4>
                </div>
              </div>

              <div className="header border-t border-muted px-5 py-3">
                <div className="grid grid-cols-1 gap-3 rounded-lg md:grid-cols-1 lg:gap-1 2xl:gap-1">
                  <span className="text-base font-bold text-[#777777]">
                    Home Address
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium  text-[#00283E]">
                    Home Address
                  </h4>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-3">
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    City
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                    Lorem
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    State
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium  text-[#00283E]">
                    LOR
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Zip
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] text-[#00283E]">
                    12345
                  </h4>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-2">
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Phone
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] text-[#00283E]">
                    {data?.booking_data?.customer?.phone_no}
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Birth Date
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] text-[#00283E]">
                    “01/02/1999”
                  </h4>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-3">
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Drivers License number
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] text-[#00283E]">
                    “AB132456”
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    State
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] text-[#00283E]">
                    LOR
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Expires
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] text-[#00283E]">
                    “01/02/1999”
                  </h4>
                </div>
              </div>
            </div>

            <div className="mt-5 rounded-lg border border-muted">
              <div className="header rounded-lg bg-[#f7f7f7] px-5 py-3">
                <h4 className="text-lg">Customer Insurance Information</h4>
              </div>

              <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-2">
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Insurer
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                    Lorem
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Agent
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                    Lorem
                  </h4>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-3">
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Policy Number
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                    12345
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Expires
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                    “01/02/1999”
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Phone
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                    “123-456-7890”
                  </h4>
                </div>
              </div>
            </div>

            <div className="mt-5 rounded-lg border border-muted">
              <div className="header rounded-lg bg-[#f7f7f7] px-5 py-3">
                <h4 className="text-lg">Additional Driver</h4>
              </div>

              <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-2">
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Additional Driver Name
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                    Lorem
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Birth Date
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                    “01/02/1999”
                  </h4>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-3">
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Drivers License number
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                    “AB132456”
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    State
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                    “LOR”
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Expires
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                    “01/02/1999”
                  </h4>
                </div>
              </div>
            </div>

            <div className="mt-5 rounded-lg border border-muted">
              <div className="header rounded-lg bg-[#f7f7f7] px-5 py-3">
                <h4 className="text-lg">Customer Vehicle Information</h4>
              </div>

              <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-3">
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    License number
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium  text-[#00283E]">
                    “AB132456”
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    State
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                    LOR
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    VIN
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium  text-[#00283E]">
                    12345
                  </h4>
                </div>
              </div>

              <div className="header border-t border-muted px-5 py-3">
                <div className="grid grid-cols-1 gap-3 rounded-lg md:grid-cols-1 lg:gap-1 2xl:gap-1">
                  <span className="text-base font-bold text-[#777777]">
                    Year/Make/Model/Color
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                    “Lorem Ipsum”
                  </h4>
                </div>
              </div>
            </div>

            <div className="mt-5 rounded-lg border border-muted">
              <div className="header rounded-lg bg-[#f7f7f7] px-5 py-3">
                <h4 className="text-lg">Rental Vehicle Information</h4>
              </div>

              <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-3">
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Pickup Date & Time
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                    {formatDate(data?.booking_data?.pick_up_time)}
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Dropoff Date & Time
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                    {formatDate(data?.booking_data?.drop_off_time)}
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Booked At
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                    {formatDate(data?.booking_data?.created_at)}
                  </h4>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-2">
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Vehicle ID
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                    {" "}
                    {data?.booking_data?.fleet?.id}
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Vehicle Name
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                    {data?.booking_data?.fleet?.name}
                  </h4>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-3">
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Vehicle VIN
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                    {data?.booking_data?.fleet?.vin_number}
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    License Number
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                    {" "}
                    {data?.booking_data?.fleet?.plate_number}
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Vehicle Colour
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                    {data?.booking_data?.fleet?.color}
                  </h4>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-2">
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Minimum Miles
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                    {" "}
                    {data?.booking_data?.fleet?.miles_min}
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Maximum Miles
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                    {" "}
                    {data?.booking_data?.fleet?.miles_max}
                  </h4>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-2">
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Miles Allowed
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                    {" "}
                    {data?.booking_data?.fleet?.miles_include}
                  </h4>
                </div>
                <div className="border border-b-0 border-muted px-5 py-3">
                  <span className="text-base font-bold text-[#777777]">
                    Miles Overage
                  </span>
                  <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                    {data?.booking_data?.fleet?.miles_overage}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="rounded-lg border border-muted">
          <div className="grid  gap-0 border-t  border-muted ">
              <h4 className="rounded-lg bg-[#f7f7f7] px-5 py-3 text-lg">
                Bill Summary
              </h4>
            </div>

            <div className="grid grid-cols-2 gap-0 border-t border-muted md:grid-cols-2 ">
              <h4 className="border-r px-5 py-3 text-lg font-medium text-[#00283E]">
                Extras
              </h4>
              <h4 className="px-5 py-3 text-lg font-medium text-[#00283E]">
                {data?.booking_data?.invoice?.extras_total}
              </h4>
            </div>

            {/* <div className="grid grid-cols-2 gap-0 border-t border-muted md:grid-cols-2">
              <h4 className="border-r px-5 py-3 text-lg font-medium text-[#00283E]">
                ----
              </h4>
              <h4 className="px-5 py-3 text-lg font-medium text-[#00283E]">
                ----
              </h4>
            </div> */}

            <div className="grid grid-cols-2 gap-0 border-t  border-muted md:grid-cols-2">
              <h4 className="rounded-lg bg-[#f7f7f7] px-5 py-3 text-lg">
                Sub Total
              </h4>
              <h4 className="rounded-lg bg-[#f7f7f7] px-5 py-3 text-lg">
                {data?.booking_data?.invoice?.total_price_without_security}
              </h4>
            </div>

            <div className="grid grid-cols-2 gap-0 border-t border-muted md:grid-cols-2 ">
              <h4 className="border-r px-5 py-3 text-lg font-medium text-[#00283E]">
                Payments
              </h4>
              <h4 className="px-5 py-3 text-lg font-medium text-[#00283E]">
                ----
              </h4>
            </div>

            <div className="grid grid-cols-2 gap-0 border-t border-muted md:grid-cols-2">
              <h4 className="border-r px-5 py-3 text-lg font-medium text-[#00283E]">
                Refunds
              </h4>
              <h4 className="px-5 py-3 text-lg font-medium text-[#00283E]">
                ----
              </h4>
            </div>

            <div className="grid grid-cols-2 gap-0 border-t  border-muted md:grid-cols-2">
              <h4 className="rounded-lg bg-[#f7f7f7] px-5 py-3 text-lg">
                Deposit Held
              </h4>
              <h4 className="rounded-lg bg-[#f7f7f7] px-5 py-3 text-lg">
                {data?.booking_data?.invoice?.security_deposit_total}
              </h4>
            </div>
          </div>

          <div className="mt-5 rounded-lg border border-muted">
            <div className="header rounded-lg bg-[#f7f7f7] px-5 py-3">
              <h4 className="text-lg">Renters Signature</h4>
            </div>

            <div className="header border-t border-muted px-5 py-3">
              <div className="grid grid-cols-1 gap-3 rounded-lg md:grid-cols-1 lg:gap-1 2xl:gap-1">
                <span className="text-base font-bold text-[#777777]">
                  Signature
                </span>
                <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] italic">
                  {data?.booking_data?.customer?.identity_verification_details
                    ? data?.booking_data?.customer?.identity_verification_details
                        ?.first_name +
                      " " +
                      data?.booking_data?.customer?.identity_verification_details?.last_name
                    : data?.booking_data?.customer?.first_name +
                      " " +
                      data?.booking_data?.customer?.last_name}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RentalAgreement;
