import { FC, useEffect, useState } from "react";
import { CarDataType } from "data/types";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";

import FleetCard from "components/FleetCard/FleetCard";

import { useNavigate } from "react-router-dom";
import { useData } from "data/data-provider";
import { CarListingModal } from "models/vehicleModal";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: CarDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
}) => {
  const navigate = useNavigate();
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const {
    availableFleet,
    pickupLocation,
    setSelectedCar,
    user,
    setIsAuthPanelOpen,
  } = useData();
  let maxPrice = availableFleet
    ? availableFleet.reduce((acc, current) => Math.max(acc, current.price), 0)
    : 0;
  const [filteredFleet, setFilteredFleet] = useState<CarListingModal[] | []>(
    availableFleet ?? []
  );
  const [rangePrices, setRangePrices] = useState([0, maxPrice]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!pickupLocation) {
      navigate("/");
    }
  }, [pickupLocation]);

  useEffect(() => {
    if (
      selectedTypes.length == 0 ||
      Array.from(
        new Set(availableFleet!.map((car) => car?.fleet_class))
      ).filter((item): item is any => item !== undefined).length ==
        selectedTypes.length
    ) {
      setFilteredFleet(availableFleet ?? []);
    } else {
      setFilteredFleet(
        availableFleet!.filter((item: CarListingModal) =>
          selectedTypes.includes(item?.fleet_class?.name ?? "")
        )
      );
    }
  }, [selectedTypes, availableFleet]);

  useEffect(() => {
    const filteredFleet = availableFleet?.filter(
      (item: any) =>
        item.price >= rangePrices[0] && item.price <= rangePrices[1]
    );

    setFilteredFleet(filteredFleet ?? []);
  }, [rangePrices, availableFleet]);

  const renderCard = (car: any) => {
    return (
      <FleetCard
        key={car.id}
        data={car}
        simpleImg={true}
        onClick={() => {
          {
            setSelectedCar(car);
            // setSelectedExtras(new Map());
            // setSelectedBonzahProduct([]);
            // setSelectedInsuranceProtection([]);
            // setCouponResponse(null);
            if (user) {
              navigate("/listing-car-detail");
            }else{
              setIsAuthPanelOpen(true);
              
            }
          }
          return;
        }}
      />
    );
  };
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      style={{ minHeight: "80vh" }}
      data-nc-id="SectionGridFilterCard"
    >
      <div className="sm:flex-row justify-between align-center">
        <Heading2
          heading={"Available Cars (" + availableFleet?.length + ")"}
          subHeading=" "
        />

        <div className="my-8 flex items-center">
          <TabFilters
            types={[
              ...Array.from(
                new Set(
                  availableFleet?.map((car) => car?.fleet_class?.name ?? "")
                )
              ).filter((item): item is any => item !== undefined),
            ]}
            selectedTypes={selectedTypes}
            setSelectedTypes={setSelectedTypes}
            rangePrices={rangePrices}
            setRangePrices={setRangePrices}
            maxPrice={maxPrice}
          />
        </div>
      </div>
      <div className="nc-SectionGridFeaturePlaces relative">
        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
        >
          {filteredFleet?.map((car: any) => renderCard(car))}
        </div>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
