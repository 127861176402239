import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
}

const HomeAboutSection: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className} mb-20 lg:mb-36`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row items-center relative text-center lg:text-left lg:space-x-10">
        {/* Text Section with 50% width */}
        <div className="w-full lg:w-1/2 space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-bold text-neutral-900 md:text-5xl  dark:text-[#E5E7EB]">
            Welcome to Level305
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            A travel concierge service here in Miami,
            <br /> We specialize in car rentals, house rentals, boat rentals,
            club/dinner reservations and more! We pride ourselves in excellent
            customer service.
            <br /> With a dedicated team behind you every step of the way, we
            ensure a worry and hassle-free experience from start to finish!
          </span>
        </div>

        {/* Image Section with 50% width */}
        <div className="w-full mt-12 lg:mt-0 lg:w-1/2 flex-grow">
          <img className="w-full rounded-xl" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HomeAboutSection;
